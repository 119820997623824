































































































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import i18n from '@/lang'
import ModalShowProfile from '@/components/Modal/ModalShowProfile.vue'
import { InvitedMember, EInviteBy } from '@/models'
import GroupMemberService from '@/services/GroupMemberService'
import PreviewText from '@/helpers/PreviewText'
import UserService from '@/services/UserService'

/**
 * f2-302
 * グループ招待中画面
 */
@Component({ components: { ModalShowProfile } })
export default class Invited extends Vue {
  private invitedMember: InvitedMemberViewModel[] = []
  private eIviteBy = EInviteBy
  private modalMess: string = ''
  private selectedMember: InvitedMember = new InvitedMember()
  private noAvatar = require('@/assets/images/avatar-default.png')
  private selectedUserId: number = -1

  created() {
    moment.locale('ja')
    this.getInvitedMembers()
  }

  getInvitedMembers() {
    GroupMemberService.getInvitedMembersByGroupId(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.invitedMember = res.data.map(
            (item: InvitedMember) => new InvitedMemberViewModel(item)
          )
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
  }

  reInviteMember(member: InvitedMember) {
    this.$blockui.show()
    GroupMemberService.reInvitedMember(this.$route.params.groupId, member.token)
      .then(res => {
        if (res.status === 200) {
          this.getInvitedMembers()
        }
      })
      .catch(err => {
        if (
          err.response.status == 400 &&
          err.response.data.message == 'invalid_member_status'
        ) {
          this.modalMess = this.$t(
            'common.message.invalid_member_status_invite'
          ) as string
        } else if (
          err.response.status == 404 &&
          err.response.data.message == 'group_not_found_or_stopped'
        ) {
          this.modalMess = this.$t(
            'common.message.group_not_found_or_deleted'
          ) as string
        } else {
          this.modalMess = this.$t('common.message.invite_fail') as string
        }
        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }

  openDelInvite(member: InvitedMember) {
    this.selectedMember = member
    this.$bvModal.show('modal-confirm')
  }

  handleDelInvite() {
    this.$blockui.show()
    GroupMemberService.delStopInvite(
      this.$route.params.groupId,
      this.selectedMember.token
    )
      .then(res => {
        if (res.status === 200) {
          this.getInvitedMembers()
          this.$bvModal.show('modal-success')
          this.modalMess = this.$t('common.message.del_stoped') as string
        }
      })
      .catch(err => {
        if (
          err.response.status == 404 &&
          err.response.data.message == 'group_not_found_or_stopped'
        ) {
          this.modalMess = this.$t(
            'common.message.group_not_found_or_deleted'
          ) as string
        } else {
          this.modalMess = this.$t('common.message.del_stop_fail') as string
        }

        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }

  async showMemberProfile(userId: number) {
    this.selectedUserId = userId
    // const sameGroupTag = await this.getUserProfileById()
    // if (sameGroupTag) {
    this.$bvModal.show('modal-show-profile')
    // }
  }

  /**
   * Check user is same group tag
   */
  // async getUserProfileById() {
  //   return UserService.getUserProfileById(this.selectedUserId)
  //     .then(res => {
  //       return res.status === 200 && res.data.is_same_group_tag
  //     })
  //     .catch(() => {
  //       return false
  //     })
  // }

  handleLongText(text?: string) {
    return PreviewText.covertToPreviewText(text ? text : '')
  }
}

class InvitedMemberViewModel extends InvitedMember {
  inviteDateVM: string = ''
  is_expert: boolean = false
  full_name: string = ''
  avatar?: string
  invite_type: EInviteBy = EInviteBy.LINK

  constructor(invitedMember: InvitedMember) {
    super()
    Object.assign(this, invitedMember)

    //inviteDateVM
    const invatedDate = moment(this.updated_at)
    this.inviteDateVM = invatedDate.format(
      i18n.t('common.date_format.mm_dd') as string
    )

    //is_expert
    const expireDate = moment(this.expired_date)
    this.is_expert = expireDate.isBefore(moment())

    //full_name
    this.full_name = [
      invitedMember.user_profile?.first_name,
      invitedMember.user_profile?.last_name
    ].join(' ')

    //avatar
    this.avatar = invitedMember.user_profile?.avatar

    //invite_type
    this.invite_type = invitedMember.user_profile
      ? EInviteBy.LINK
      : EInviteBy.MAIL
  }
}
